<template>
  <div>
    <pop-up
      body="আরও ভাল অভিজ্ঞতার জন্য দয়া করে আপনার মোবাইলটি ঘোরান।"
      img="opengraph_image.webp"
      v-if="showPopUp"
    />
    <div v-if="!iframeOn">
      <loader
        inner-class="{color: red,'text-align': center,padding: 25px,position: relative,top: 175px,left: 10px}"
      />
    </div>
    <div v-else-if="iframeOn && !apiCall">
      <pop-up :body="errMsg" img="opengraph_image.webp" :flag="flag" />
    </div>
    <div v-else-if="iframeOn && apiCall" :class="$root.mainWrapper">
      <div class="bk-btn">
        <button @click="back">
          <img src="../assets/back-btn.svg" />&nbsp;&nbsp;&nbsp;পিছনে
        </button>
      </div>
      <iframe
        id="inlineFrameExample"
        title="Hullor"
        :src="gameSrc"
        style="border: none;"
        height="100%"
        width="100%"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
import PopUp from "@/components/PopUp";

export default {
  name: "GamePlay",
  components: {
    loader: Loader,
    PopUp: PopUp
  },
  data: () => {
    return {
      iframeOn: 0,
      gameSrc: null,
      isPlayedGame: true,
      apiCall: false,
      showPopUp: false,
      flag: false,
      displayType: "",
      errMsg: "দুঃখিত! দয়া করে আবার চেষ্টা করুন"
    };
  },
  async created() {
    let userLocalStorageData = this.$root.get_localstorage_user_data(
      "userInfo"
    );
    let { msisdn } = userLocalStorageData;
    if (msisdn === undefined) {
      return this.$router.push("/");
    } else {
      try {
        const host = this.$root.url();
        const path = "api/v1/hullor/game/play";
        const url = `${host}/${path}`;
        let data = {
          app_type: "wap",
          msisdn: msisdn,
          tournamentId: this.$route.params.gameId
        };
        let response = await axios.post(url, data, {
          headers: {
            token: "f3827eeb536655b578de9aaeb53acf73"
          }
        });
        this.iframeOn = 1;
        if (response.data.status === "SUCCESS") {
          this.apiCall = true;
          this.gameSrc = response.data.gameRedirectUrl;
          if (response.data.display_type === "landscape") {
            this.displayType = response.data.display_type;
            this.showPopUp = window.innerHeight > window.innerWidth;
          }
          return true;
        } else {
          this.flag = true;
          return (this.errMsg = response.data.message);
        }
      } catch (e) {
        this.iframeOn = 1;
        console.log("catch block ", e);
        return true;
      }
    }
  },
  mounted() {
    window.onresize = () => {
      if (this.displayType === "landscape") {
        this.showPopUp = window.innerHeight > window.innerWidth;
      }
    };
  },
  methods: {
    back() {
      if (confirm("আপনি কি ফিরে যেতে চান?")) {
        return this.$router.push({ name: "Enrolled" });
      }
    }
  }
};
</script>

<style scoped>
.rotateScreen {
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg);
}
</style>
