<template>
  <div class="loader" :class="innerClass">
    <p v-if="loadingText" v-text="loadingText">&nbsp;</p>
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: ["loadingText", "innerClass"]
};
</script>
