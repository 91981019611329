<template>
  <div class="modal-payment" id="rotate">
    <div class="modal-content-sc">
      <span class="close" @click="hideModal('rotate')"></span>
      <h5>
        <img :src="path + img" class="gp-sim" />
      </h5>
      <p>
        {{ body }}
        <span v-if="flag"
          ><a
            target="_blank"
            href="https://www.facebook.com/groups/hullorbangladesh"
            >বিস্তারিত জানতে ভিসিট</a
          ></span
        >
      </p>
    </div>
  </div>
</template>

<script>
import popup from "@/js/popup";

export default {
  name: "PopUp",
  props: {
    body: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      path: "/images/"
    };
  },
  methods: {
    hideModal: function(id) {
      let { flag } = this;
      popup.hideModal(id);
      if (flag) {
        this.$router.push({ name: "Enrolled" });
        return window.open("https://www.facebook.com/groups/hullorbangladesh");
      }
    }
  }
};
</script>

<style scoped></style>
